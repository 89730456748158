.upload-form {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 0 auto;
}

.alert {
    margin-bottom: 15px;
}

.progress-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.progress-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: conic-gradient(#007bff 0%, #e0e0e0 0%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.progress-text {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}
