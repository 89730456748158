/* Üldised stiilid */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Pealkirja stiil */
h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

/* Filterite stiil */
.filter-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.filter-container .form-select,
.filter-container .form-control {
    flex: 1;
    min-width: 200px;
}

/* Tabeli stiilid */
.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.table th,
.table td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #ddd;
}

.table th {
    background-color: #f4f4f4;
    color: #555;
    font-weight: bold;
}

.table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table tr:hover {
    background-color: #f1f1f1;
}

.table td button {
    margin-right: 5px;
}

/* Nuppude stiilid */
.btn {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.btn-success {
    background-color: #28a745;
    color: #fff;
}

.btn-success:hover {
    background-color: #218838;
}

.btn-danger {
    background-color: #dc3545;
    color: #fff;
}

.btn-danger:hover {
    background-color: #c82333;
}

.btn:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

/* Teadete stiilid */
.alert {
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 14px;
}

.alert-info {
    background-color: #e9f7fe;
    color: #31708f;
}

.alert-warning {
    background-color: #fff3cd;
    color: #856404;
}

/* Lehekülgede navigeerimise stiilid */
.pagination-container {
    text-align: center;
}

.pagination {
    display: inline-flex;
    list-style: none;
    padding: 0;
}

.pagination .page-item {
    margin: 0 5px;
}

.pagination .page-item .page-link {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-decoration: none;
    color: #007bff;
    transition: background-color 0.3s ease;
}

.pagination .page-item .page-link:hover {
    background-color: #007bff;
    color: #fff;
}

.pagination .page-item.active .page-link {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

.pagination .page-item.disabled .page-link {
    color: #ddd;
    cursor: not-allowed;
}

/* Väikese ekraani kohandused */
@media (max-width: 768px) {
    .filter-container {
        flex-direction: column;
        gap: 15px;
    }

    .table th,
    .table td {
        font-size: 12px;
    }

    .btn {
        font-size: 12px;
    }
}

.input-group {
    display: flex;
    align-items: center;
}

.input-group .form-control {
    margin-right: 8px;
    width: auto;
    flex-grow: 1;
}

.btn-sm {
    font-size: 14px;
    padding: 6px 12px;
}
