.container {
    margin-top: 20px;
    font-family: Arial, sans-serif;
}

h1, h2 {
    color: #4A4A4A;
}

.list-group {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
}

.list-group-item {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.list-group-item:last-child {
    border-bottom: none;
}

.list-group-item-primary {
    background-color: #f0f8ff;
}

.btn {
    font-size: 14px;
    padding: 5px 10px;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    background-color: #0056b3;
    border-color: #004085;
}

.btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    background-color: #c82333;
    border-color: #bd2130;
}

.alert {
    font-size: 14px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}

.alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
}

.alert-warning {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
}

.edit-user-form {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.edit-user-form h2 {
    color: #333;
    margin-bottom: 20px;
}

.form-label {
    font-weight: bold;
    color: #555;
}

.form-control {
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.form-select {
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

button {
    border-radius: 4px;
}

button:hover {
    opacity: 0.9;
}

.add-user-form {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
}
